import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_LANGUAGE, DEFAULT_PRELOAD_LANGUAGE } from 'constants/localization';
import { getLanguageWithCountry, getLocalizationFromAvailable } from 'helpers/localization';

const withSetLocaleFromParams = (Component) => {
  const WrappedComponent = (props) => {
    const navigatorLanguage = getLocalizationFromAvailable();
    const language = navigatorLanguage || props?.match?.params?.language || DEFAULT_LANGUAGE;
    const languageWithCountry = getLanguageWithCountry(language) || DEFAULT_PRELOAD_LANGUAGE;

    const { i18n } = useTranslation();

    useEffect(() => {
      localStorage.setItem('language', language);

      i18n.changeLanguage(languageWithCountry);
    }, []);

    return <Component {...props} />;
  };

  WrappedComponent.displayName = `withSetLocaleFromParams(${Component.displayName})`;

  return WrappedComponent;
};

export default withSetLocaleFromParams;
