import { Init } from 'testania';

import config from 'config';

import {
  DEFAULT_EXPIRE_CONFIG_TIME,
  DEFAULT_FLOW,
  DEFAULT_FLOW_GP,
  DEFAULT_FLOW_PROJECT_NAME,
  DEFAULT_FLOW_TC,
} from 'constants/defaultFlows';

import { checkFbBots } from 'helpers/utilsUpdated';
import { getConfigBy, getConfigFromLocalStorageBy, getVariantBranchName } from './helpers';

export const getTestaniaConfig = (flow: string | null, isGeneralFlow: boolean): Init => {
  const defaultFlow = flow === 'track_cycle' ? DEFAULT_FLOW_TC : DEFAULT_FLOW_GP;

  const testName = getConfigFromLocalStorageBy('test-name', DEFAULT_FLOW);
  const testNameWithPrefix = `${testName}_${flow === 'track_cycle' ? 'TC' : 'GP'}`;

  return {
    project: DEFAULT_FLOW_PROJECT_NAME,
    testName: isGeneralFlow ? testNameWithPrefix : getConfigBy('test-name', 'default'),
    branchName: getConfigFromLocalStorageBy('branch-name', 'default'),
    // @ts-ignore
    variantName: getVariantBranchName(),
    defaultBranches: ['default'],
    defaultFlow: isGeneralFlow ? defaultFlow : DEFAULT_FLOW_GP,
    testaniaUrl: config.TESTANIA_HOST,
    testaniaApiKey: config.TESTANIA_X_API_KEY,
    expireConfigTime: DEFAULT_EXPIRE_CONFIG_TIME,
    runOnlyLocalConfig: checkFbBots(),
  };
};
