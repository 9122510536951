import {
  AVAILABLE_LOCALIZATIONS,
  AVAILABLE_LOCALIZATIONS_WITH_COUNTRY,
  CHINESE_LANGUAGE,
  DEFAULT_NAVIGATOR_LANGUAGE,
} from 'constants/localization';

export const getNavigatorLanguage = (withCountry?: boolean) => {
  let language;

  if (navigator?.languages?.length) {
    language = navigator.languages[0];
  } else {
    language = navigator?.language || DEFAULT_NAVIGATOR_LANGUAGE;
  }

  return withCountry ? language.replace('-', '_') : language?.split('-')[0];
};

export const getLocalizationFromAvailable = () => {
  const navigatorLanguage = getNavigatorLanguage();

  return AVAILABLE_LOCALIZATIONS.includes(navigatorLanguage) ? navigatorLanguage : null;
};

export const getCurrentLocalization = () => {
  const routerLang = window.location.pathname.split('/')[1];

  return AVAILABLE_LOCALIZATIONS.includes(routerLang) ? routerLang : null;
};

export const getLanguageWithCountry = (lang: string): string | null => {
  if (lang === CHINESE_LANGUAGE) {
    const navigatorLanguage = getNavigatorLanguage(true);

    return AVAILABLE_LOCALIZATIONS_WITH_COUNTRY.includes(navigatorLanguage) ? navigatorLanguage : null;
  }

  return AVAILABLE_LOCALIZATIONS_WITH_COUNTRY.find((item) => item.includes(lang)) || null;
};
