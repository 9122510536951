import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const selectOnboarding = (state: RootState) => state.onboarding;

const selectUrlParams = createSelector(selectOnboarding, (onboarding) => onboarding.urlParams);
const selectOnboardingScreensList = createSelector(selectOnboarding, (onboarding) => onboarding.onboardingScreensList);
const selectBirthYear = createSelector(selectOnboarding, (onboarding) => onboarding.birthYear);
const selectConceptionLength = createSelector(selectOnboarding, (onboarding) => onboarding.conceptionLength);
const selectCycleDuration = createSelector(selectOnboarding, (onboarding) => onboarding.cycleDuration);
const selectFirstPregnancy = createSelector(selectOnboarding, (onboarding) => onboarding.firstPregnancy);
const selectHeight = createSelector(selectOnboarding, (onboarding) => onboarding.height);
const selectLastPeriod = createSelector(selectOnboarding, (onboarding) => onboarding.lastPeriod);
const selectHormonalBalance = createSelector(selectOnboarding, (onboarding) => onboarding.hormonalBalance);

export {
  selectOnboarding,
  selectUrlParams,
  selectOnboardingScreensList,
  selectBirthYear,
  selectConceptionLength,
  selectCycleDuration,
  selectFirstPregnancy,
  selectHeight,
  selectLastPeriod,
  selectHormonalBalance,
};
