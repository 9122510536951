import { Main } from 'wikr-core-analytics';

// services
import sentry from 'services/Sentry/Sentry';

// constants
import { COOKIE_GROUP_LIST } from 'constants/analytics';
import { ERROR_LEVELS, SENTRY_ONE_TRUST } from 'constants/sentry';

// helpers
import { getAllAnalyticInitList, getAnalyticInitList } from './helper';

const stringToArray = (str) => (str ? str.slice(1, -1).split(',') : []);

const isAcceptedAllCookies = (cookieList) => COOKIE_GROUP_LIST.every((groupId) => cookieList.includes(groupId));

export default class OneTrust {
  #instance;

  constructor(OneTrust) {
    this.#instance = OneTrust;
    this.isAllowedAll =
      OneTrust?.IsAlertBoxClosed() && isAcceptedAllCookies(stringToArray(window?.OptanonActiveGroups));
    this.isBannerClosed = OneTrust?.IsAlertBoxClosed();
    this.acceptedCookieList = OneTrust?.IsAlertBoxClosed() ? stringToArray(window?.OptanonActiveGroups) : [];

    this.branchName = localStorage.getItem('branch-name');
  }

  setError(methodName) {
    sentry.logError(new Error(`OneTrust method ${methodName} don't work`), SENTRY_ONE_TRUST, ERROR_LEVELS.CRITICAL);
  }

  consentHandler(oneTrustEvent) {
    const { detail } = oneTrustEvent;

    if (!this.isBannerClosed) {
      this.acceptedCookieList = detail;
    }

    const cookieList = this.isBannerClosed ? this.acceptedCookieList : detail;

    this.isAllowedAll = isAcceptedAllCookies(cookieList);

    const activeSystems = getAnalyticInitList(cookieList, this.branchName);

    if (this.isAllowedAll && !this.isBannerClosed) {
      if (activeSystems.length) {
        Main.initSystems({ activeSystems });

        this.isBannerClosed = true;
      }
    } else if (activeSystems.length && this.isBannerClosed) {
      Main.initSystems({ activeSystems });
    }
  }

  init() {
    if (this.isBannerClosed) {
      this.consentHandler({ detail: this.acceptedCookieList });
    } else {
      this.#instance?.OnConsentChanged
        ? this.#instance.OnConsentChanged(this.consentHandler)
        : this.setError('OnConsentChanged');
    }
  }

  initRemarketing() {
    const runRemarketingAnalytics = () => this.consentHandler({ detail: COOKIE_GROUP_LIST });

    if (this.isBannerClosed) {
      runRemarketingAnalytics();
    } else {
      if (this.#instance?.OnConsentChanged) {
        this.#instance.OnConsentChanged(() => {
          if (!this.isAllowedAll) {
            runRemarketingAnalytics();

            this.#instance?.AllowAll ? this.#instance.AllowAll() : this.setError('AllowAll');
          }
        });
      } else {
        this.setError('OnConsentChanged');
      }
    }
  }

  rejectAll() {
    this.#instance?.RejectAll ? this.#instance?.RejectAll() : this.setError('rejectAll');
  }

  allowAll() {
    if (!this.isAllowedAll) {
      const activeSystems = getAllAnalyticInitList(this.branchName);

      Main.initSystems({ activeSystems });

      this.isAllowedAll = true;
      this.#instance?.AllowAll ? this.#instance.AllowAll() : this.setError('AllowAll');
    }
  }
}
