import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import getTimerTime from 'helpers/timer';

import { CurrentProduct, IPaymentFlow } from 'types/payments/payments';

import { setAllProductsFromFirstPayment, setCurrentProduct, setPaymentDiscount } from '../redux/Payment/actions';
import { selectPayment } from 'redux/Payment/selectors';
import { getDiscountForPayment, getDiscountForPaymentFixed } from '../helpers/utils';

export interface IUsePaymentLogic {
  pageInfo: IPaymentFlow;
  productsListWithDiscount: CurrentProduct[];
  productsListWithoutDiscount: CurrentProduct[];
  timerKey?: string;
  withCurrentProductSetting?: boolean;
  timerPeriod?: number;
}

export const usePaymentLogic = ({
                                  pageInfo,
                                  productsListWithDiscount = [],
                                  productsListWithoutDiscount = [],
                                  timerKey = 'PaymentScreenDefaultTimer',
                                  timerPeriod,
                                  withCurrentProductSetting = false,
                                }: IUsePaymentLogic) => {
  const dispatch = useDispatch();
  const discount = useRef(0);

  const productsWithAndWithoutDiscount = [...productsListWithDiscount, ...productsListWithoutDiscount];

  useEffect(() => {
    dispatch(setAllProductsFromFirstPayment(productsWithAndWithoutDiscount));

    return () => {
      dispatch(setPaymentDiscount(discount.current));
    };
  }, []);

  const timerTime = getTimerTime({ key: timerKey }, timerPeriod);

  const { currentProduct } = useSelector(selectPayment);

  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
  const [currentProductForPayment, setCurrentProductForPayment] = useState<CurrentProduct>(
    (currentProduct as CurrentProduct) || pageInfo.products[0],
  );
  const [currentNotDiscountedProduct, setCurrentNotDiscountedProduct] = useState<CurrentProduct>(productsListWithoutDiscount[0]);
  const [isTimerEnd, setIsTimerEnd] = useState(timerTime.seconds <= 0);

  const productsIfThereAreNoDiscountedProducts =
    productsListWithDiscount.length === 0 ? productsListWithoutDiscount : productsListWithDiscount;

  const products = !isTimerEnd ? productsIfThereAreNoDiscountedProducts : productsListWithoutDiscount;

  useEffect(() => {
    if (withCurrentProductSetting) {
      products.forEach((item) => item.is_preselected && setPaymentPrice(item));
    }
  }, [isTimerEnd]);

  const setNotDiscountedProduct = (currentProduct: CurrentProduct) => {
    const notDiscountedProduct = productsListWithoutDiscount.find((item) => item.trial === currentProduct.trial && item.period === currentProduct.period);

    if (notDiscountedProduct) {
      setCurrentNotDiscountedProduct(notDiscountedProduct);
    }
  };

  const setPaymentPrice = (currentProduct: CurrentProduct) => {
    if (!isPaymentModalOpen) {
      discount.current = getDiscountForPayment(currentProduct, pageInfo);

      dispatch(setCurrentProduct(currentProduct));
      setCurrentProductForPayment(currentProduct);
      setNotDiscountedProduct(currentProduct);
    }
  };

  const setPaymentPriceNew = (currentProduct: CurrentProduct) => {
    if (!isPaymentModalOpen) {
      discount.current = getDiscountForPaymentFixed(currentProduct, pageInfo);

      dispatch(setCurrentProduct(currentProduct));
    }
  };

  const openPaymentModal = () => {
    setCurrentProductForPayment(currentProduct as CurrentProduct);
    setNotDiscountedProduct(currentProduct as CurrentProduct);
    setPaymentModalOpen(true);
  };

  const paymentModalHandler = () => {
    setPaymentModalOpen(false);
  };
  const onTimerEnd = () => {
    setIsTimerEnd(true);
    discount.current = 0;
  };

  return {
    setPaymentPrice,
    openPaymentModal,
    isPaymentModalOpen,
    currentProductForPayment,
    currentNotDiscountedProduct,
    paymentModalHandler,
    setPaymentPriceNew,
    timerTime,
    onTimerEnd,
    discount,
    isTimerEnd,
    products,
    productsWithAndWithoutDiscount,
  };
};
