export const onboardingScreensList = [
  'ob-email-marketing',
  'ob-doctor-checkup',
  'ob-reproductive-disorders',
  'ob-mood-swings',
  'ob-mental-health',
  'ob-sex-issues',
  'ob-interesting-topics-tc',
  'ob-interesting-topics-gp',
  'ob-cycle-regularity',
  'ob-cycle-duration',
  'ob-period-duration',
  'ob-health-problems',
  'ob-plan-creation',
  'ob-plan-creation-steps',
  'ob-enter-email',
  'ob-prenatal-supplements',
  'ob-experienced-symptoms',
  'ob-birth-year',
  'ob-birth-year-sex',
  'ob-first-pregnancy',
  'ob-lifestyle-adjustment',
  'ob-job-type',
  'ob-conception-length',
  'ob-ovulation-calculation',
  // 'ob-intro-gp',
  'ob-intro-tc',
  'ob-gp-explanation',
  'ob-social-proof',
  'ob-birth-control',
  'ob-height',
  'ob-weight',
  'ob-cramps',
  'ob-job-description',
  'ob-how-long-cycle',
  'ob-ever-birth-control',
  'ob-children-number-v2',
  'ob-back-pain',
  'ob-sleep',
  'ob-business',
  'ob-best-graph',
  'ob-first-log',
  'ob-feelings',
  'ob-when-to-conceive',
  'ob-diet',
  'ob-symptoms-actual',
  'ob-examining',
  'ob-health-check',
  'ob-did-you-know',
  'ob-body-signals',
  'ob-confiding',
  'ob-fertility-target',
  'ob-boost-chart',
  'ob-doctor-comment',
  'ob-plan-creation-bp',
  'ob-pregnancy-support',
  'ob-before-after',
  'ob-welcome',
  'ob-sex-start',
  'ob-describe-pleasure',
  'ob-different-orgasms',
  'ob-kegel',
  'ob-sexual-pleasure',
  'ob-body-connection',
  'ob-partner-connection',
  'ob-orgasm-overthinking',
  'ob-changes-intimacy',
  'ob-blockers',
  'ob-feel-best',
  'ob-cycle-sex',
  'ob-sex-drive',
  'ob-orgasms-comeback',
  'ob-orgasm-benefits',
  'ob-boost-pleasure',
  'ob-sex-graph',
  'ob-patterns',
  'ob-goal-sex',
  'ob-goal-sex-new',
  'ob-plan-creation-steps-lv',
  'ob-enter-email-sex',
  'ob-enter-email-sex-new',
  'ob-goal-gp',
  'ob-goal-general',
  'ob-intro-gp-flow',
  'ob-intro-tc-flow',
  'ob-choose-us-flow',
  'ob-social-proof-flow',
  'ob-doctor-comment-tc',
  'ob-social-proof-flow-tc',
  'ob-gn-how-conceive',
  'ob-gn-health-issues',
  'ob-gn-given-birth',
  'ob-gn-why',
  'ob-gn-priorities',
  'ob-gn-food-relationships',
  'ob-gn-typical-day',
  'ob-gn-miscarriage',
  'ob-gn-pcos',
  'ob-gn-sti',
  'ob-gn-chemicals',
  'ob-gn-unlock',
  'ob-gn-half-way',
  'ob-gn-email',
  'ob-gn-review-first',
  'ob-gn-chart',
  'ob-gn-loading',
  'ob-gn-longpage',
  'ob-gn-help',
  'ob-gn-triggers',
  'ob-gn-eating-habits',
  'ob-gn-state-of-mind',
  'ob-gn-period-duration',
  'ob-gn-hormonal-balance',
  'ob-gn-dietery-restrictions',
  'ob-gn-physical-limitation',
  'ob-gn-interrelated',
  'ob-gn-statement-which',
  'ob-gn-statement-partner',
  'ob-gn-statement-motivation',
  'ob-gn-together',
  'ob-gn-hbanswer',
  'ob-gn-beyonce',
  'ob-gn-life-changes',
  'ob-gn-good-hands',
  'ob-gn-long-anim',
  'ob-gn-confidence',
  'ob-gn-uncertain',
  'ob-gn-we-are-glad',
  'ob-gn-gpfaster-1',
  'ob-gn-gpfaster-2',
  'ob-gn-pace',
  'ob-gn-meal-prep',
  'ob-gn-birth-control',
  'ob-gn-fitness-tracker',
  'ob-gn-services',
  'ob-gn-just-a-wish',
  'ob-gn-not-enough',
  'ob-prepayment',
  'ob-gn-plan-creating',
  'ob-gn-articles',
  'ob-energy-levels',
  'ob-mood-emotions',
  'ob-choose-us-cleanse',
  'ob-choose-us-real',
  'ob-goal-gp-new',
  'ob-save-money-chart',
  'ob-block-eggs',
  'ob-block-intimacy',
  'ob-block-hormones',
  'ob-block-psych',
  'ob-eggs-state',
  'ob-feel-best-new',
  'ob-cycle-sex-new',
  'ob-initiate-sex',
  'ob-feel-loved',
  'ob-quest-desire',
  'ob-3-month-desire',
  'ob-month-desire',
  'ob-sex-satisfaction',
  'ob-hard-orgasm',
  'ob-partner-trust',
  'ob-sex-state',
  'ob-birth-control-new',
  'ob-think-about-look',
  'ob-sex-over-fun',
  'ob-sex-relax',
  'ob-tired-to-sex',
  'ob-sex-confidence',
  'ob-sex-mood',
  'ob-block-profile',
  'ob-block-mind',
  'ob-block-relation',
  'ob-block-sex-drive',
  'ob-body-lang',
  'ob-choose-us-sex',
  'ob-couple-bond',
  'ob-improve-sex',
  'ob-vibrant-sex',
  'ob-boost-pleasure-new',
  'ob-plan-creation-steps-lv-new',
  'ob-select-celeb',
  'ob-top-obgyns',
  'ob-are-you-from-doctor',
  'ob-results-blockers',
  'ob-sex-talk',
  'ob-sex-tech-third',
  'ob-feel-good',
  'ob-solo-play',
  'ob-solo-music',
  'ob-self-pleasure',
  'ob-solo-empowered',
  'ob-desire-question',
  'ob-kinky-me',
  'ob-sex-tech-fourth',
  'ob-sex-tech-first',
  'ob-sex-tech-second',
  'ob-relation-improve',
  'ob-pleasure-goal',
  'ob-self-aware',
  'ob-block-sexmind',
  'ob-block-pleasure',
  'ob-block-reward',
  'ob-meno-emotions',
  'ob-meno-symptoms',
  'ob-relationships',
  'ob-sex-menstruation',
  'ob-sex-expert',
  'ob-sex-pregnant',
  'ob-try-techniques',
  'ob-meno-graph',
];

export const DEFAULT_EMPTY_URL_PARAMS = { data: 'empty_url_store' };
