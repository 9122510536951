import React from 'react';
import cx from 'classnames';

import { Panel } from 'libComponents';

import styles from './SingleUnitInput.module.css';

const SingleUnitInput = ({
                           unit,
                           placeholder,
                           value,
                           inputRef,
                           onChange,
                           onKeyDown,
                           successMessage,
                           failMessage,
                           simpleFailMessage,
                           showFailMessage,
                           showSuccessMessage,
                           showSimpleFailMessage,
                           type,
                           isDefaultValue,
                           disabled,
                         }: any) => {

  return (
    <div className={styles.container}>
      <label className={cx(styles.label, styles[type])}>
        <input
          type='number'
          pattern='[0-9]*'
          maxLength={3}
          placeholder={placeholder}
          value={value}
          ref={inputRef}
          onChange={e => onChange(e)}
          onKeyDown={e => onKeyDown(e)}
          className={cx(styles.input, {
            [styles.inputError]: showSimpleFailMessage,
          })}
          disabled={disabled}
        />
        {unit && (<div className={styles.units}>{unit}</div>)}
      </label>
      {!isDefaultValue && simpleFailMessage && showSimpleFailMessage &&
        <div className={styles.simpleFailTitle}>{simpleFailMessage}</div>
      }
      {!isDefaultValue && successMessage && showSuccessMessage &&
        <Panel title={successMessage.title} body={successMessage.body} type='success' />}
      {!isDefaultValue && failMessage && showFailMessage &&
        <Panel title={failMessage.title} body={failMessage.body} type='fail' />}
    </div>
  );
};

export default SingleUnitInput;
