import { all, put, takeLatest } from 'redux-saga/effects';
import { AnalyticUtils } from 'wikr-core-analytics';

import api from 'apiSingleton';

import { setIsAutologinComplete, setIsPaid, setToken, setUserEmail, setUserId } from 'redux/User/actions';
import { INIT_AUTO_LOGIN } from 'redux/Onboadring/actionTypes';
import { setUserGoal } from 'redux/Onboadring/actions';
import { getUrlParams } from '../../../helpers/utils';


export function* autoLogin() {
  const params = getUrlParams();
  const { token: refreshToken } = params;

  if (!refreshToken) return;

  try {
    // const language = getLocalizationFromAvailable();
    // const languageFromURL = getCurrentLocalization();

    // yield put(setLanguage(language || languageFromURL || DEFAULT_LANGUAGE));

    const uuid = localStorage.getItem('uuid');

    if (!uuid) {
      yield AnalyticUtils.getUuid();
    }

    const { token, refresh_token } = yield api.user.generateTokens({ refresh_token: refreshToken });

    yield put(setToken({ token, refresh_token }));

    // @ts-ignore
    const response = yield api.user.getUser();

    const userData = response.data;

    // const measureSystem = userData?.units?.toUpperCase?.() || defaultMeasureSystem;

    const {
      email,
      id,
      // is_password,
      is_paid,
      main_user_goal,
      // height,
      // weight,
      // target_weight,
      // fitness_level,
      // current_bodytype,
      // target_bodytype,
      // date_of_birth,
    } = userData;

    // sentry.setUser({ email, user_id: id });
    //
    // if (is_password && is_paid) {
    //   moveToCabinet({ token, awsId });
    //
    //   return;
    // }

    yield all([
      put(setUserEmail(email)),
      put(setUserId(id)),
      put(setIsPaid(is_paid)),
      put(setUserGoal(main_user_goal || 'get_pregnant')),
      put(setIsAutologinComplete(true)),

      // put(setMeasure(measureSystem)),
      // put(
      //   setTall({
      //     unit: measureSystem,
      //     value: height || defaultTall,
      //   }),
      // ),
      // put(
      //   setCurrentWeight({
      //     unit: measureSystem,
      //     value: weight || defaultCurrentWeight,
      //   }),
      // ),
      // put(
      //   setTargetWeight({
      //     unit: measureSystem,
      //     value: target_weight || defaultTargetWeight,
      //   }),
      // ),
      // put(setFitnessLvl(fitness_level || defaultFitnessLevel)),
      // put(setCurrentBody(current_bodytype || defaultCurrentBody)),
      // put(setTargetBody(target_bodytype || defaultTargetBody)),
      // put(setAge(getAgeFromBirthday(date_of_birth || defaultDateOfBirth))),
      // is_password && put(setIsLogin(is_password)),
    ]);
  } catch (error) {
    yield put(setIsAutologinComplete(true));

    // sentryCaptureEvent(error, SENTRY_APP, ERROR_LEVELS.WARNING);
  }
}

export const autoLoginSaga = [takeLatest(INIT_AUTO_LOGIN, autoLogin)];
