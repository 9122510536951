import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-xhr-backend';

import { getCurrentLocalization, getLanguageWithCountry, getLocalizationFromAvailable } from 'helpers/localization';
import { DEFAULT_LANGUAGE, DEFAULT_PRELOAD_LANGUAGE } from 'constants/localization';
import { getConfigBy } from 'pages/GeneralPages/InitTestania/config/helpers';

const localeFromStorage = localStorage.getItem('language');
const userGoal = localStorage.getItem('userGoal');

const navigatorLanguage = getLocalizationFromAvailable();
const languageFromURL = getCurrentLocalization();

const language = navigatorLanguage || languageFromURL || localeFromStorage || DEFAULT_LANGUAGE;
const languageWithCountry = getLanguageWithCountry(language) || DEFAULT_PRELOAD_LANGUAGE;
const branchName = getConfigBy('branch-name');

const isLvBranchName = branchName === 'lv';
const isEmailBranchName = branchName === 'email';

const isDefaultPreloadLanguage = isLvBranchName || (isEmailBranchName && userGoal === 'track_cycle');

localStorage.setItem('language', language);

i18n.use(backend)
  .use(initReactI18next)
  .init({
    lng: isDefaultPreloadLanguage ? DEFAULT_PRELOAD_LANGUAGE : languageWithCountry,
    fallbackLng: [DEFAULT_PRELOAD_LANGUAGE],
    ns: ['general', 'sex_funnel'],
    fallbackNS: 'sex_funnel',
    backend: {
      loadPath: '/locales/{{ns}}/{{lng}}/translation.json',
    },
    preload: [DEFAULT_PRELOAD_LANGUAGE],
    debug: false,
    react: {
      useSuspense: false,
    },
    nsSeparator: false,
    keySeparator: false,
  });

export default i18n;
