import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// services
import { OneTrust } from 'services/OneTrustProvider';
import sentry from 'services/Sentry/Sentry';
import { initAnalytics } from 'services/Analytics';

// helpers
import { isRemarketingFlow as checkIsRemarketing } from 'helpers/utilsUpdated';

// redux
import { selectIsPaymentScreen } from 'redux/Payment/selectors';

// constants
import { ERROR_LEVELS, SENTRY_ONE_TRUST } from 'constants/sentry';

const DEFAULT_CONFIG = { childList: true, subtree: true };

export const useOneTrustLoaded = (selector = 'onetrust-consent-sdk') => {
  const isPaymentScreen = useSelector(selectIsPaymentScreen);
  const [isOneTrustLoaded, setIsOneTrustLoaded] = useState(false);
  const [isOneTrustFail, setIsOneTrustFail] = useState(false);
  const [isRemarketingFlow, setIsRemarketingFlow] = useState(false);
  const [oneTrust, setOneTrust] = useState(null);

  const branchName = localStorage.getItem('branch-name');

  new Promise(() => {
    const isOneTrustError = localStorage.getItem('isOneTrustError');

    if (isOneTrustError && !isOneTrustFail) {
      setIsOneTrustFail(true);

      sentry.logError(new Error(isOneTrustError), SENTRY_ONE_TRUST, ERROR_LEVELS.CRITICAL, {
        host: window.location.host,
        href: window.location.href,
      });

      return;
    }

    if (document.getElementById(selector) && !isOneTrustLoaded) {
      setIsOneTrustLoaded(true);
    }

    new MutationObserver((_, observer) => {
      if (document.getElementById(selector) && !isOneTrustLoaded) {
        setIsOneTrustLoaded(true);
        observer.disconnect();
      }
    }).observe(document.body, DEFAULT_CONFIG);
  });

  useEffect(() => {
    if (isOneTrustLoaded) {
      const { OneTrust: instance } = window;

      setOneTrust(new OneTrust(instance));
    }
  }, [isOneTrustLoaded]);

  useEffect(() => {
    const isRemarketing = checkIsRemarketing();

    isRemarketing && setIsRemarketingFlow(true);

    if (oneTrust && !isRemarketingFlow) {
      oneTrust?.init();
    }

    if (oneTrust && isRemarketingFlow) {
      oneTrust?.initRemarketing();
    }
  }, [oneTrust]);

  useEffect(() => {
    if (isPaymentScreen && oneTrust && !isRemarketingFlow) {
      oneTrust?.allowAll();
    }

    if (isPaymentScreen && isOneTrustFail) {
      initAnalytics(branchName);
    }
  }, [isPaymentScreen, oneTrust]);

  return { isOneTrustLoaded, oneTrust };
};

export default useOneTrustLoaded;
