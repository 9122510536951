import { all, put, select, takeLatest } from 'redux-saga/effects';
import { Main } from 'wikr-core-analytics';

import api from 'apiSingleton';

import {
  disableIsLoading,
  enableIsLoading,
  setEmailError,
  setToken,
  setUserEmail,
  setUserId,
  signUp,
  signUpSuccess,
  updateUserInfoFail,
} from 'redux/User/actions';
import { setCurrency as setCurrencyToStore } from 'redux/Payment/actions';
import { selectCountry } from 'redux/User/selectors';
import * as actionTypes from 'redux/User/actionTypes';

import { GoogleAds } from 'services/Analytics/Systems/Google';

import { UserInfoResponse } from 'types/user/userApiInterface';

import { COUNTRIES_CURRENCY_LIST } from 'constants/countriesList';
import { PAYMENT_DEFAULT_CURRENCY } from 'constants/payments';

function* signUpSaga({ payload: { payload, add, toNextPage } }: ReturnType<typeof signUp>) {
  try {
    const data: UserInfoResponse = yield api.user.anonymousSignUp(payload);
    const { token, refresh_token: refreshToken, id, email, web_token: webToken } = data;

    yield all([
      put(setEmailError(null)),
      put(setToken({ token, refreshToken, webToken })),
      put(signUpSuccess(data)),
      put(setUserId(id)),
      put(setUserEmail(email)),
    ]);

    if (add) {
      api.user.flowUpdate({ add, delete: [] });
    }

    Main.customData('lead', {});
    GoogleAds.track('lead');

    /* Issue with Testania. Need to use toNextPage like macrotask, only here! */
    setTimeout(() => {
      toNextPage();
    });

  } catch (error) {
    yield put(disableIsLoading());
    if (error.status === 403) {
      yield put(setEmailError('registration.email.error'));
    }
    console.error(error);
  }
}

function* setCurrency() {
  const userCountry: string = yield select(selectCountry);

  const filteredCountry = COUNTRIES_CURRENCY_LIST.filter(({ code }) => code === userCountry);

  if (userCountry) {
    // check if country included to config list
    if (filteredCountry.length) {
      const { currency } = filteredCountry.find((item) => item.currency) ?? {
        currency: PAYMENT_DEFAULT_CURRENCY,
      };

      yield put(setCurrencyToStore(currency));
    }
  }

  if (!userCountry || !filteredCountry.length) {
    yield put(setCurrencyToStore(PAYMENT_DEFAULT_CURRENCY));
  }
}

function* updateMarketingMailingSaga({ payload: { data, toNextPage } }: any) {
  try {
    const { email_sending_consent } = data;

    const requestData = {
      ads_tracking_consent: true,
      app_improvement_consent: true,
      email_sending_consent,
    };

    yield api.user.updateMarketingEmailing(requestData);

    yield put(disableIsLoading());

    toNextPage();
  } catch (e) {
    yield put(disableIsLoading());
    console.error(e);
  }
}

function* updateUserInfoSaga({ payload }: any) {
  const { data, toNextPage, fieldError } = payload;
  const {
    email,
    password,
    name,
    country,
  } = data;

  yield put(enableIsLoading());

  try {
    yield api.user.emailSignUp({ email: email.toLowerCase(), password });
    yield api.user.updateUser({ name, country });

    yield put(disableIsLoading());

    toNextPage();
  } catch (e) {
    yield put(updateUserInfoFail(e));
    fieldError(e);
  }
}

// function* updateFlowSaga({ payload: { add, delete: deleteProp } }: ReturnType<typeof signUp>) {
//   try {
//     const token = localStorage.getItem('token');
//     console.log('deleteProp', deleteProp, token);
//     yield api.user.flowUpdate({ add, delete: deleteProp });
//   } catch (error) {
//     console.log('hey', error);
//     console.error(error);
//   }
// }

export default [
  takeLatest(actionTypes.SIGN_UP, signUpSaga),
  // takeLatest(actionTypes.UPDATE_FLOW, updateFlowSaga),
  takeLatest(actionTypes.SET_COUNTRY, setCurrency),
  takeLatest(actionTypes.UPDATE_MARKETING_MAILING, updateMarketingMailingSaga),
  takeLatest(actionTypes.UPDATE_INFO, updateUserInfoSaga),
];
