import Base from './Base';

import {
  FlowUpdateData,
  GenerateTokensData,
  SignUpData,
  UserInfoResponse,
  UserUpdateData,
} from 'types/user/userApiInterface';

class UserApi extends Base {
  async anonymousSignUp<Data = SignUpData, Response = UserInfoResponse>(payload: Data): Promise<Partial<Response> | unknown> {
    return await this.apiClient.public.post<Data, Response>('anonymous-sign-up/', payload);
  }

  async flowUpdate<Data = FlowUpdateData, Response = Record<string, unknown>>(payload: Data): Promise<null | unknown> {
    return await this.apiClient.private.post<Data, Response>('flow/', payload);
  }

  async updateMarketingEmailing<Data = UserUpdateData, Response = UserInfoResponse>(payload: Data): Promise<Partial<Response> | unknown> {
    return await this.apiClient.public.post<Data, Response>('user/consent/', payload);
  }

  async getUser<Response = UserInfoResponse>(): Promise<Partial<Response> | unknown> {
    return await this.apiClient.private.get<Response>('user/');
  }

  async emailSignUp<Data, Response = UserInfoResponse>(payload: Data): Promise<Partial<Response> | unknown> {
    return await this.apiClient.private.post<Data, Response>('email-sign-up/', payload);
  }

  async updateUser<Data, Response = UserInfoResponse>(payload: Data): Promise<Partial<Response> | unknown> {
    return await this.apiClient.private.patch<Data, Response>('user/', payload);
  }

  async generateTokens(payload: GenerateTokensData): Promise<Partial<Response> | unknown> {
    return await this.apiClient.public.generateTokens(payload);
  }
}

export default UserApi;
