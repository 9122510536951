import React from 'react';

import withSetLocalizationFromParams from 'HOC/withSetLocaleFromParams';
import Loader from 'components/Loader/Loader';

import styles from './Styles.module.css';

const PreloadTestania = () => (
    <div className={styles.loaderWrapper}>
        <Loader isLoading={true} additionalClass="app" />
    </div>
);

export default withSetLocalizationFromParams(PreloadTestania);
