export interface IPaymentTypes {
  CREDIT_CARD: number;
  GOOGLE_PAY: number;
  PAYPAL: number;
  APPLE_PAY: number;
}

export const DAYS_PER_YEAR = 365;
export const DAYS_PER_MONTH = 31;
export const DAYS_PER_WEEK = 7;
export const DAYS_PER_TWO_MONTH = 62;
export const DAYS_PER_THREE_MONTH = 90;
export const DAYS_PER_FOUR_MONTH = 121;
export const DAYS_PER_FIVE_MONTH = 152;
export const DAYS_PER_SIX_MONTH = 180;
export const DAYS_PER_SEVEN_MONTH = 212;
export const DAYS_PER_EIGHT_MONTH = 243;
export const DAYS_PER_ONE_YEAR = 365;

export const PAYMENT_FLOW_PERIODS = [
  DAYS_PER_MONTH,
  DAYS_PER_TWO_MONTH,
  DAYS_PER_THREE_MONTH,
  DAYS_PER_FOUR_MONTH,
  DAYS_PER_FIVE_MONTH,
  DAYS_PER_SIX_MONTH,
  DAYS_PER_SEVEN_MONTH,
  DAYS_PER_EIGHT_MONTH,
  DAYS_PER_YEAR,
  DAYS_PER_WEEK,
];

export const INTRODUCTORY_A_FLOW_PAYMENT_ID = 'payment_Aa_checkout_Alpha1';
export const INTRODUCTORY_B_FLOW_PAYMENT_ID = 'payment_Ab_Checkout_Alpha1';

export const PAYMENT_DEFAULT_CURRENCY = {
  sign: '$',
  name: 'USD',
};

export const CREDIT_CARD_LITERAL = 'CREDIT_CARD';
export const PAYPAL_LITERAL = 'PAYPAL';
export const APPLE_PAY_LITERAL = 'APPLE_PAY';
export const GOOGLE_PAY_LITERAL = 'GOOGLE_PAY';

export type TPaymentMethod = typeof CREDIT_CARD_LITERAL | typeof PAYPAL_LITERAL;

export const PAYMENT_FIRST_PRICE = '1';
export const PAYMENT_SECOND_PRICE = '24';

export type TPaymentPrice = typeof PAYMENT_FIRST_PRICE | typeof PAYMENT_SECOND_PRICE;
export const PAYMENT_TYPES: IPaymentTypes = {
  CREDIT_CARD: 1,
  GOOGLE_PAY: 1,
  PAYPAL: 4,
  APPLE_PAY: 1,
};

// 2.01 - Invalid Data
// 2.03 - Invalid Currency
// 2.05 - Order not Found
// 2.10 - Invalid 3DS flow on the merchant side
// 2.15 - SCA require 3D authentication
const SOLID_INVALID_DATA_CODES = ['2.01', '2.03', '2.05', '2.10', '2.15'];

// 5.02 - Invalid Card Token
// 5.03 - Application Error
// 5.04 - Merchant is not configured yet
// 5.05 - Merchant is not activated yet
const SOLID_PROCESSING_ERRORS_CODES = ['5.02', '5.03', '5.04', '5.05'];

// 6.01 - Unknown decline code
export const SOLID_DECLINED_UNKNOWN_CODES = ['6.01'];

// 7.01 - Card Token not found
// 7.02 - Google payment error
// 7.03 - Smart cascade decline
// 7.04 - 3DS cascade to 2D
// 7.05 - Apple online payment error
// 7.06 - Token generation error
const SOLID_DECLINED_PAYMENTS_CODES = ['7.01', '7.02', '7.03', '7.04', '7.05', '7.07'];

export const SOLID_ERROR_CODES = [
  ...SOLID_INVALID_DATA_CODES,
  ...SOLID_PROCESSING_ERRORS_CODES,
  ...SOLID_DECLINED_PAYMENTS_CODES,
  ...SOLID_DECLINED_UNKNOWN_CODES,
];

export const PAYMENT_TYPES_NAME_ID = {
  CREDIT_CARD: 'bankCard',
  PAYPAL: 'payPal',
  APPLE_PAY: 'applePay',
  GOOGLE_PAY: 'paymentGenerator',
};

export const DEVICE_OS = {
  ANDROID: 'ANDROID',
  IOS: 'IOS',
};

export const APP_FULL_ACCESS = 'app_full_access';

export const PAYMENT_TYPES_NAME = {
  CREDIT_CARD: 'Bank_Card',
  PAYPAL: 'PayPal',
  APPLE_PAY: 'apple_pay',
  GOOGLE_PAY: 'google_pay',
};

export const PRODUCT_CODES = [
  APP_FULL_ACCESS,
] as const;

export const PAY_PAL_INIT_ID = 'paypal-button';
